import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <div style={{ textAlign: 'center', marginTop: '30vh', height:'50vh' }}>
      <h1 style={{fontSize:'3em'}}>404 - Page Not Found</h1>
      <p style={{fontSize:'2em'}}>Sorry, the page you are looking for does not exist.</p>
      <Link to="/" style={{fontSize:'2em'}}>Go to Home</Link>
    </div>
  );
};

export default NotFound;
